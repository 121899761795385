const EXAMPLE_QUESTIONS = {
  en: {
    title: 'Example questions:',
    list: [
      { text: 'Can mindfulness prevent burnout?', number: 1 },
      { text: 'How to assess stroke risk in atrial fibrillation?', number: 2 },
      {
        text: 'Are statins cost-effective in the treatment of cardiovascular diseases?',
        number: 3,
      },
    ],
  },
  nl: {
    title: 'Voorbeeldvragen:',
    list: [
      { text: 'Kan mindfulness burnout voorkomen?', number: 1 },
      { text: 'Hoe beoordeel je het risico op een beroerte bij boezemfibrilleren?', number: 2 },
      { text: 'Zijn statines bij hart- en vaatziekten kosteneffectief?', number: 3 },
    ],
  },
};

const extractReferencesFromResponse = (text, replaceWithFunction) => {
  // Remove
  // [id1234]  -> remove 'id'
  // [id 1234] -> remove 'id '
  // [id:1234] -> remove 'id:'
  // [id: 1234] -> remove 'id: '
  // [ID1234] -> remove 'ID'
  // [ID 1234] -> remove 'ID '
  // [ID:1234] -> remove 'ID:'
  // [ID: 1234] -> remove 'ID: '
  // [Id1234] -> remove 'ID'
  // [Id 1234] -> remove 'ID '
  // [Id:1234] -> remove 'ID:'
  // [Id: 1234] -> remove 'ID: '
  // [^1234]

  const regexID = /\[\s*(id|\^|#)+(\s*:?\s*)?(\d+)\s*\]/gi;

  // Remove
  // (ID: 33757520) -> [33757520]

  const regexIDParens = /\(\s*(id|\^|#)+(\s*:?\s*)?(\d+)\s*\)/gi;

  return (
    text
      .replace(regexID, '[$3]') // See questionHelpers.test.js for examples
      .replace(regexIDParens, '[$3]') // See questionHelpers.test.js for examples
      .replace(/\\u(\d{4})/g, '\\u$1')
      .replace(/\[\\\[id\\\]\]\((\d+)\)/g, (_, match) => `[${match}]`) // TODO remove when chat answer is fixed
      .replace(/\[\\\[id\\\]\]/g, () => '*') // TODO remove when chat answer is fixed
      .replace(/\[(id|ID|Id)(:|)(number|NUMBER)\]/g, () => '*') // TODO remove when chat answer is fixed
      .replace(/\[(id|ID|Id)\]/g, () => '*') // TODO remove when chat answer is fixed
      //For the array of [id: 1234, ID 12334, Id:125] -> [1234,12334,125]
      .replace(/\[(?:\s*id\s*:?\s*\d+\s*,?)+\s*\]/gi, (match) => {
        // Find all the ids in the match and join them with a comma
        const ids = match.match(/\d+/g);
        return `[${ids.join(',')}]`;
      })
      .replace(/\[([\d\s,]+)\]/g, (_, match) => replaceWithFunction(match))
  );
};

const CHAT_RESPONSE_LANGUAGE_BY_DEFAULT = 'en';

const NO_RESULT_CONSTANT = 'NO_RESULT';

const isItNoResult = (content) => content === NO_RESULT_CONSTANT;

const NUMBER_LIMIT_OF_SELECTED_DOCUMENTS_FOR_REGENERATE_ANSWER = 15;

export {
  EXAMPLE_QUESTIONS,
  CHAT_RESPONSE_LANGUAGE_BY_DEFAULT,
  NO_RESULT_CONSTANT,
  NUMBER_LIMIT_OF_SELECTED_DOCUMENTS_FOR_REGENERATE_ANSWER,
  extractReferencesFromResponse,
  isItNoResult,
};
